import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbCardModule,
  NbDatepickerModule,
  NbDialogModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbTabsetModule,
  NbToastrModule,
  NbTooltipModule,
  NbUserModule,
  NbWindowModule,
} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideHighlightOptions } from 'ngx-highlightjs';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { environment } from '../environments/environment';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CachedRouteReuseStrategy } from './cache-components-route-reuse-strategy';
import { PagesModule } from './pages/pages.module';
import { AddRequestHeaderInterceptor } from './shared/interceptors/add-request-header.interceptor';
import { LoadingInterceptor } from './shared/interceptors/loading.interceptor';
import { ResponseCheckInterceptor } from './shared/interceptors/response-check.interceptor';
import { FlowSummaryPanelService } from './shared/services/flow-summary-panel.service';
import { FlowTabTempService } from './shared/services/flow-tab-temp.service';
import { SidebarMenuService } from './shared/services/sidebar-menu.service';
import { TimezoneService } from './shared/services/timezone.service';
import { ToastrContainerService } from './shared/services/toastr-container.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    NbTooltipModule,
    FormsModule,
    NbEvaIconsModule,
    ReactiveFormsModule,
    OverlayModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.INFO : NgxLoggerLevel.DEBUG,
    }),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'username',
          baseEndpoint: environment.newApi,
          login: {
            endpoint: '/login',
            method: 'post',
            redirect: {
              // success: '/welcome/',
              failure: null, // stay on the same page
            },
          },
        }),
      ],
      forms: {},
    }),
    NbUserModule,
    NbCardModule,
    AppRoutingModule,
    NbLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NbTabsetModule,
    PagesModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot({
      duration: 10000,
      preventDuplicates: true,
      duplicatesBehaviour: 'previous',
      toastClass: 'no-title-toast',
    }),
    NbDateFnsDateModule.forRoot({
      format: 'dd/MM/yyyy',
    }),
    NgIdleModule.forRoot(),
    NgbModule,
  ],
  providers: [
    TimezoneService,
    ToastrContainerService,
    FlowTabTempService,
    FlowSummaryPanelService,
    SidebarMenuService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddRequestHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseCheckInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CachedRouteReuseStrategy,
    },
    provideHighlightOptions({
      fullLibraryLoader: () => import('highlight.js'),
    }),
    // {
    //   provide: HIGHLIGHT_OPTIONS,
    //   useValue: {
    //     themePath: 'assets/styles/solarized-dark.css',
    //   },
    // },
    provideCharts(withDefaultRegisterables()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
